import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import styled from 'styled-components';

const HeroImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  @media (min-width: 1201px) {
    height: 700px;
  }
  @media (max-width: 1200px) {
    height: 500px;
  }
  @media (max-width: 900px) {
    height: 300px;
  }

  @media (max-width: 500px) {
    height: 200px;
  }
`;

export default HeroImage;
