import styled from 'styled-components';

export interface PromotionContainer {
  spacing: number;
}

const PromotionContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: ${({ spacing }: PromotionContainer): number => spacing * 8}px 0
    ${({ spacing }: PromotionContainer): number => spacing * 6}px;
`;

export default PromotionContainer;
