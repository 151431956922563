import React from 'react';
import { graphql } from 'gatsby';
import {
  Button,
  Container,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TableContainer,
  Typography,
  Box,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListIcon from '@material-ui/icons/List';

import SEO from '../components/seo';
import Hero from '../components/hero';
import { DownloadQueryQuery } from '../../types/graphql-types';
import Link from '../components/Link';
import RequireAuthDownloadButton from '../components/button/require-auth--download-button';
import DefaultLink from '../components/DefaultLink';
import Promotion from '../components/promotion';
import HeroImage from '../components/image/hero';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export interface Download {
  data: DownloadQueryQuery;
}

const Download = ({ data }: Download): JSX.Element => {
  const { contentfulDownload, allContentfulBimxplorerSetup, allContentfulPdf } = data;
  const image = contentfulDownload?.heroImageLarge?.gatsbyImageData;
  const nodes = allContentfulBimxplorerSetup?.edges.map(({ node }) => node);
  const latesNode = nodes[0];

  return (
    <>
      <SEO title="Download" />
      {image && (
        <Hero>
          <HeroImage title="Hero" alt="hero" image={image as IGatsbyImageData} />
        </Hero>
      )}

      <Container>
        <Promotion
          heading={contentfulDownload?.heroHeading}
          premable={contentfulDownload?.heroPremable?.heroPremable}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <RequireAuthDownloadButton
                href={(latesNode?.setupFile?.file?.url as string) || undefined}
                variant="contained"
                color="primary"
              >
                <>
                  <GetAppIcon />
                  Download latests ({latesNode?.version})
                </>
              </RequireAuthDownloadButton>
            </Grid>
            <Grid item>
              <DefaultLink href="/releases" noTextDecoration>
                <Button variant="contained">
                  <ListIcon />
                  View all release notes
                </Button>
              </DefaultLink>
            </Grid>
          </Grid>
        </Promotion>
      </Container>

      <Container>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {nodes.map((node, index) => (
                <TableRow key={node.version || index}>
                  <TableCell component="th" scope="row">
                    v{node.version}
                  </TableCell>
                  <TableCell align="right">
                    {node?.documentation?.file?.url ? (
                      <Link
                        href={(node?.documentation?.file?.url as string) || undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {node?.documentation?.file?.url ? 'Documentation' : 'See previous'}
                      </Link>
                    ) : (
                      'See previous'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Link href={`/releases/tag/${node.slug}`}>Release notes</Link>
                  </TableCell>
                  <TableCell align="right">
                    <RequireAuthDownloadButton
                      href={(node?.setupFile?.file?.url as string) || undefined}
                      color="primary"
                    >
                      <>
                        <GetAppIcon />
                        Download ({node?.version})
                      </>
                    </RequireAuthDownloadButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={4} mb={2}>
          <Typography variant="h5" component="h2">
            PDF
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {allContentfulPdf.edges.map((edge, index) => (
                  <TableRow key={edge.node.title || index}>
                    <TableCell component="th" scope="row">
                      <Link
                        href={(edge?.node?.pdf?.file?.url as string) || undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {edge.node.title}
                      </Link>
                      {}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </>
  );
};

export default Download;

export const pageQuery = graphql`
  query DownloadQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulDownload {
      heroImageLarge {
        gatsbyImageData(width: 1920, height: 1080, quality: 90)
      }
      heroHeading
      heroPremable {
        heroPremable
      }
    }
    allContentfulBimxplorerSetup(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          version
          slug
          releaseNotes {
            childMarkdownRemark {
              html
            }
          }
          setupFile {
            file {
              url
            }
          }
          documentation {
            file {
              url
            }
          }
          title
        }
      }
    }
    allContentfulPdf {
      edges {
        node {
          title
          createdAt
          updatedAt
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
