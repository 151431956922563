import React from 'react';
import { Button, DialogContentText, DialogActions, ButtonProps } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useAuth from '../../State/Auth/useAuth';

type RequireAuthDownloadButton = ButtonProps & {
  href?: string;
  children: React.ReactNode;
};

const RequireAuthDownloadButton = ({
  href,
  children,
  ...props
}: RequireAuthDownloadButton): JSX.Element => {
  const auth = useAuth();
  const [requireAuthModal, setRequireAuthModal] = React.useState(false);

  return (
    <>
      <Button
        {...props}
        href={auth.firebaseUser ? href : undefined}
        onClick={!auth.firebaseUser ? (): void => setRequireAuthModal(true) : undefined}
      >
        {children}
      </Button>
      <Dialog open={requireAuthModal} onClose={(): void => setRequireAuthModal(false)} fullWidth>
        <DialogTitle>Sign in is required</DialogTitle>
        <DialogContent>
          <DialogContentText>In order to download you need to be signed in.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button href="/login" color="primary">
            Sign in
          </Button>
          <Button href="/sign-up" color="primary">
            Sign up
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequireAuthDownloadButton;
