import React from 'react';
import { Typography } from '@material-ui/core';

import PromotionContainer from './promotion-container';

export interface Promotion {
  heading: string | null | undefined;
  premable: string | null | undefined;
  children?: JSX.Element;
}

const Promotion = ({ heading, premable, children }: Promotion): JSX.Element => (
  <PromotionContainer spacing={8}>
    <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
      {heading}
    </Typography>
    <Typography variant="h6" align="center" color="textSecondary" paragraph>
      {premable}
    </Typography>
    {children}
  </PromotionContainer>
);

export default Promotion;
