import styled from 'styled-components';

import DefaultLink from '../DefaultLink';

const Link = styled(DefaultLink)`
  text-decoration: none;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
`;

export default Link;
